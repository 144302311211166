import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import 'react-immutable-proptypes';
import 'prop-types';
import _noop from 'lodash/noop';
import { List, Map, OrderedMap } from 'immutable';
import _isNil from 'lodash/isNil';
import '@dbh/admindev-types';
import { PACKAGE_GROUP_REFERENCES } from '@dbh/admindev-constants';
import 'invariant';
import '@dbh/validation';

/**
 * @typedef {import('./types').HotelPackage} HotelPackage
 */ /**
 * Returns `true` if the given hotel package contains a package category having
 * the given "package category reference".
 * @param {HotelPackage} hotelPackage .
 * @param {string} packageCategoryReference .
 * @return {boolean} .
 */const doesHotelPackageHavePackageCategoryWithReference=withConformsTo("doesHotelPackageHavePackageCategoryWithReference",[],(a,b)=>a.getIn(["packageAttributes","packageCategories"]).some(a=>a.get("reference")===b));var doesHotelPackageHavePackageCategoryWithReference$1 = doesHotelPackageHavePackageCategoryWithReference;

/**
 * @typedef {import('./types').HotelPackageList} HotelPackageList
 */ /**
 * Returns `true` if at least one of the given hotel packages, contain a package
 * category having the given "package category reference".
 * @param {HotelPackageList} hotelPackages .
 * @param {string} packageCategoryReference .
 * @return {boolean} .
 */const doHotelPackagesHavePackageCategoryWithReference=withConformsTo("doesHotelPackageHavePackageCategoryWithReference",[],(a,b)=>a.some(a=>doesHotelPackageHavePackageCategoryWithReference$1(a,b)));var doHotelPackagesHavePackageCategoryWithReference$1 = doHotelPackagesHavePackageCategoryWithReference;

/**
 * @typedef {import('./types').HotelPackage} HotelPackage
 */ /**
 * Returns the availability of the given hotel package.
 * @param {HotelPackage?} hotelPackage .
 * @return {number?} .
 */const getAvailabilityOfHotelPackage=withConformsTo("getAvailabilityOfHotelPackage",["hotelPackage",_noop],a=>a?a.getIn(["bookingAttributes","availability"]):void 0);var getAvailabilityOfHotelPackage$1 = getAvailabilityOfHotelPackage;

/**
 * Given hotel packages, returns only the ones whose `IDs` (`idAllotment`) are
 * found in `idPackagesList`.
 * @param {Immutable.List<Immutable.Map>} hotelPackages .
 * @param {Immutable.List<string>} idPackagesList .
 * @param {Function?} decorator If passed, it is called with every hotel package
 * as the argument, to allow transformations.
 * @return {Immutable.List<Immutable.Map>} The filtered list of hotel packages.
 */const filterHotelPackagesByIds=withConformsTo("filterHotelPackagesByIds",[],(a,b,c)=>a.reduce((a,d)=>{const e=d.get("idAllotment");return b.includes(e)?(a.push(c?c(e,d):d),a):a},List().asMutable()).asImmutable());var filterHotelPackagesByIds$1 = filterHotelPackagesByIds;

/**
 * @typedef {import('./types').GroupToPackagesMap} GroupToPackagesMap
 * @typedef {import('./types').IdAllotment} IdAllotment
 */ /**
 * Given a packages group mapping, hotel packages and the `ID` of the
 * "most relevant" package, returns the same mapping with actual hotel packages
 * instead of their `IDs` (`idAllotment`). It also sets the flag
 * `isMostRelevantPackage` in the appropriate hotel packages.
 * @param {Object} options .
 * @param {GroupToPackagesMap} options.packageGroupsMapping .
 * @param {Immutable.List<Immutable.Map>} options.hotelPackages .
 * @param {IdAllotment} options.idMostRelevantPackage .
 * @return {Immutable.Map} .
 * @throws If the parameters are not valid.
 */const getPackageGroupsMappingWithHotelPackagesAndIsMostRelevantPackageSet=withConformsTo("getPackageGroupsMappingWithHotelPackagesAndIsMostRelevantPackageSet",[],a=>{const{packageGroupsMapping:b,hotelPackages:c,idMostRelevantPackage:d}=a;return b.mapEntries(a=>{let[b,e]=a;const f=filterHotelPackagesByIds$1(c,e,(a,b)=>a===d?b.set("isMostRelevantPackage",!0):b);return [b,f]})});var getPackageGroupsMappingWithHotelPackagesAndIsMostRelevantPackageSet$1 = getPackageGroupsMappingWithHotelPackagesAndIsMostRelevantPackageSet;

/**
 * @typedef {import('./types').GroupToPackagesMap} GroupToPackagesMap
 */ /**
 * Returns the `admindev` package group reference (`reference` field in the
 * `admindev` "Navigation Menu" items) of the "first" (remember, a package
 * can belong to more than one package group) package group that contains the
 * given hotel package, if any contains it.
 * @param {GroupToPackagesMap} packageGroupsMapping .
 * @param {string?} idHotelPackage The `idAllotment` of the hotel package.
 * @return {string?} The `admindev` package group reference (`reference` field
 * in the `admindev`, you can set it for "Navigation Menu" items).
 */const getReferenceOfFirstPackageGroupWithThisPackage=withConformsTo("getReferenceOfFirstPackageGroupWithThisPackage",[],(a,b)=>_isNil(b)?void 0:a.findKey(a=>a.includes(b)));var getReferenceOfFirstPackageGroupWithThisPackage$1 = getReferenceOfFirstPackageGroupWithThisPackage;

/**
 * @typedef {import('./types').PackagesOrCartOrReservationItems} PackagesOrCartOrReservationItems
 * @typedef {import('./types').PackagesToGroupsMap} PackagesToGroupsMap
 */ /**
 * Creates and returns a mapping between package groups from `admindev` (they
 * are in a "Navigation Menu", a `webcontrol` called `package-categories-groups`)
 * and hotel packages they contain. @see `createGroupToPackagesMap`.
 * @param {Object} options .
 * @param {PackagesOrCartOrReservationItems} options.packagesOrCartOrReservationItems .
 * @param {Immutable.List} options.packageGroupsFromAdminDev .
 * @param {boolean} options.isCartOrReservationItems .
 * @param {Function} options.createGroupToPackagesMap We haven't moved this to
 * this package (yet?) because its test needs fixtures selected with selectors
 * that are not in `dbhpackages` (`shoppingcarts`, `bookings` etc).
 * @return {PackagesToGroupsMap} .
 */const createPackagesToPackageGroupsMap=withConformsTo("createPackagesToPackageGroupsMap",[],a=>{let{packagesOrCartOrReservationItems:b,packageGroupsFromAdminDev:c,isCartOrReservationItems:e=!1,createGroupToPackagesMap:d}=a;const f=d(b,c,e);return f.reduce((a,b,c)=>(b.size&&b.forEach(b=>a.update(b,a=>a?a.concat(c):List.of(c))),a),Map().asMutable()).asImmutable()});var createPackagesToPackageGroupsMap$1 = createPackagesToPackageGroupsMap;

/**
 * @typedef {import('@dbh/admindev-types').MacroCategoryReference} MacroCategoryReference
 * @typedef {import('./types').PackageGroup} PackageGroup
 */ /**
 * Returns the number of package categories in the given list, that belong to
 * the given macro category. To belong to a macro category, a package category
 * must have its reference inside `parentReference`.
 * @param {Immutable.List<PackageGroup>} packageCategories .
 * @param {MacroCategoryReference} macroCategoryReference .
 * @return {number} .
 */const howManyPackageCategoriesBelongToMacroCategory=withConformsTo("howManyPackageCategoriesBelongToMacroCategory",[],(a,b)=>a.filter(a=>a.get("parentReference")===b).size);var howManyPackageCategoriesBelongToMacroCategory$1 = howManyPackageCategoriesBelongToMacroCategory;

/**
 * @typedef {import('@dbh/admindev-types').MacroCategoryReference} MacroCategoryReference
 * @typedef {import('@dbh/admindev-types').PackageGroupReference} PackageGroupReference
 */ /**
 * Returns the reference of the package group in the given list, that has
 * the greater number of package categories that belong to the given macro
 * category. To belong to a macro category, a package category must have its
 * reference in the `parentReference` field.
 * @param {Immutable.List<Immutable.Map>} packageGroups .
 * @param {MacroCategoryReference} macroCategoryReference .
 * @return {PackageGroupReference} .
 */const getReferenceOfPackageGroupWithMorePackageCategoriesBelongingToMacro=withConformsTo("getReferenceOfPackageGroupWithMorePackageCategoriesBelongingToMacro",[],(a,b)=>{let c,d=0;return a.forEach(a=>{const e=howManyPackageCategoriesBelongToMacroCategory$1(a.get("packageCategories",b),b);(e>d||!c)&&(c=a.get("reference"),d=e);}),c});var getReferenceOfPackageGroupWithMorePackageCategoriesBelongingToMacro$1 = getReferenceOfPackageGroupWithMorePackageCategoriesBelongingToMacro;

/**
 * @typedef {import('@dbh/admindev-types').MacroCategoryReference} MacroCategoryReference
 * @typedef {import('@dbh/admindev-types').PackageGroupReference} PackageGroupReference
 * @typedef {import('./types').PackagesToGroupsMap} PackagesToGroupsMap
 */ /**
 * Calculates the package groups to which the hotel package belongs to, then
 * returns the "best matching" (using our basic business logic based on the
 * relevance of the package group, compared to the search context's macro category)
 * package group's reference, if the package belongs to multiple groups.
 * Otherwise it just returns the reference of the only package group to which
 * the package belongs.
 * @param {Object} options .
 * @param {PackagesToGroupsMap} options.packageGroupsMapping .
 * @param {number} options.idHotelPackage .
 * @param {MacroCategoryReference} options.searchMacroCategoryReference .
 * @param {Immutable.List} options.packageGroupsFromAdminDev .
 * @return {PackageGroupReference?} .
 * @throws If the parameters are not valid.
 */const getReferenceOfBestMatchingPackageGroupThatContainsThisPackage=withConformsTo("getReferenceOfBestMatchingPackageGroupThatContainsThisPackage",[],a=>{const{idHotelPackage:b,packageGroupsFromAdminDev:c,searchMacroCategoryReference:d,packageGroupsMapping:e}=a,f=c.filter(a=>{const c=a.get("reference"),d=e.get(c),f=d&&d.includes(b);return f}),g=f.size;return g?d&&1!==f.size?getReferenceOfPackageGroupWithMorePackageCategoriesBelongingToMacro$1(f,d):f.getIn([0,"reference"]):void 0;// The package belong to two or more groups, let's return the reference of
// the "best matching" group.
});var getReferenceOfBestMatchingPackageGroupThatContainsThisPackage$1 = getReferenceOfBestMatchingPackageGroupThatContainsThisPackage;

/**
 * @typedef {import('./types').PackageCategory} PackageCategory
 */ /**
 * Returns `true` if the two given categories have the same reference.
 * @param {PackageCategory} categoryOne .
 * @param {PackageCategory} categoryTwo .
 * @return {boolean} .
 */const arePackageCategoriesEqual=(a,b)=>a.get("reference")===b.get("reference"),doPackageCategoryListsHaveOneCommonCategory=(a,b)=>b.some(b=>a.some(a=>arePackageCategoriesEqual(b,a))),shouldPackageGroupIncludeHotelPackage=withConformsTo("shouldPackageGroupIncludeHotelPackage",[],(a,b,c)=>{const d=a===PACKAGE_GROUP_REFERENCES.EXTRAS;// In case the `admindev` package group is the "extra" group, the business requirement
// is that, if the given hotel package has more than one "package category", it is
// not enough if one of them belongs to the `admindev` package group;
// they must all belong to it.
return d&&1<c.size?c.every(a=>b.some(b=>arePackageCategoriesEqual(a,b))):doPackageCategoryListsHaveOneCommonCategory(b,c)});/**
 * Checks if the two given package categories lists, have at least one package
 * category in common.
 * @param {Immutable.List<PackageCategory>} packageCategoriesOne .
 * @param {Immutable.List<PackageCategory>} packageCategoriesTwo .
 * @return {boolean} .
 */ /**
 * Returns `true` if the given `admindev` package group should include the given
 * hotel package, the logic is based on the `packageCategories` of both.
 * @param {string} packageGroupReference .
 * @param {Immutable.List<PackageCategory>} packageCategoriesOfPackageGroup .
 * @param {Immutable.List<PackageCategory>} packageCategoriesOfHotelPackage .
 * @return {boolean} .
 */var shouldPackageGroupIncludeHotelPackage$1 = shouldPackageGroupIncludeHotelPackage;

/**
 * Creates and returns a mapping between package groups from `admindev` (they are
 * in a "Navigation Menu", a `webcontrol`) and hotel packages they contain.
 * @param {Immutable.List<Immutable.Map>} packagesOrCartOrReservationItems Contains
 * `items` (`shoppingcarts` `API`) of `reservations` (`bookings` `API`) or simply
 * packages (from `POST` `/hotels` or hotel details `API`).
 * @param {Immutable.List} packageGroupsFromAdminDev Package groups returned
 * from the `/webcontrols?url=` `API`.
 * @param {boolean?} isCartOrReservationItems `true` if `packagesOrCartOr...`
 * contains shopping cart items or reservations. Otherwise the function handles
 * `packagesOrCartOr...` as a list of hotel packages.
 * @return {Immutable.Map} .
 */const createGroupToPackagesMap=withConformsTo("createGroupToPackagesMap",[],(a,b,c)=>{const d=List(),e=a.reduce((a,e)=>{const f=e.get("idAllotment"),g=c?e.get("package"):e;const h=g.getIn(["packageAttributes","packageCategories"]);return b.forEach(b=>{const c=b.get("reference"),e=b.get("packageCategories"),g=shouldPackageGroupIncludeHotelPackage$1(c,e,h);a.update(c,a=>a?g?a.push(f):a:g?List([f]):d);}),a},// The `OrderedMap` has the additional guarantee that the iteration order
// of entries will be the order in which they were set.
// @see {@link https://immutable-js.github.io/immutable-js/docs/#/OrderedMap}
OrderedMap().asMutable());// Iterate the hotel packages and without changing their order (they are
// supposed to be ordered by the `API` and we want to keep that), assign
// them to their `admindev` package group, if any. The match is generally
// done like this: if the hotel package contains at least one "package category"
// assigned to the `admindev` package group.
// We want the keys (`admindev` package group references) to be in the same
// order as they are in the `admindev` `webcontrol` ("Navigation Menu") they
// come from. This way, the tabs in the `UI` will be in the desired order.
return e.filter(a=>a.count()).asImmutable()});var createGroupToPackagesMap$1 = createGroupToPackagesMap;

/**
 * @typedef {import('@dbh/hotel-package-helpers').GroupToPackagesMap} GroupToPackagesMap
 */ /**
 * Returns the `ID` of the most relevant package, selected using a fallback
 * logic. The most relevant package is the one we highlight in the `UI`.
 * Sometimes it is in the `URL`, other times it's not (or the one in the `URL`
 * is not found or invalid), so we have to compute it ourselves.
 * @param {GroupToPackagesMap} packageGroupsMapping .
 * @return {string} The `ID` (`idAllotment`) of the hotel package selected as
 * the most relevant.
 */const getFallbackMostRelevantPackage=withConformsTo("getFallbackMostRelevantPackage",[],a=>// We pick the first package of the first group.
a.valueSeq().getIn([0,0]));var getFallbackMostRelevantPackage$1 = getFallbackMostRelevantPackage;

export { createGroupToPackagesMap$1 as createGroupToPackagesMap, createPackagesToPackageGroupsMap$1 as createPackagesToPackageGroupsMap, doHotelPackagesHavePackageCategoryWithReference$1 as doHotelPackagesHavePackageCategoryWithReference, doesHotelPackageHavePackageCategoryWithReference$1 as doesHotelPackageHavePackageCategoryWithReference, filterHotelPackagesByIds$1 as filterHotelPackagesByIds, getAvailabilityOfHotelPackage$1 as getAvailabilityOfHotelPackage, getFallbackMostRelevantPackage$1 as getFallbackMostRelevantPackage, getPackageGroupsMappingWithHotelPackagesAndIsMostRelevantPackageSet$1 as getPackageGroupsMappingWithHotelPackagesAndIsMostRelevantPackageSet, getReferenceOfBestMatchingPackageGroupThatContainsThisPackage$1 as getReferenceOfBestMatchingPackageGroupThatContainsThisPackage, getReferenceOfFirstPackageGroupWithThisPackage$1 as getReferenceOfFirstPackageGroupWithThisPackage, getReferenceOfPackageGroupWithMorePackageCategoriesBelongingToMacro$1 as getReferenceOfPackageGroupWithMorePackageCategoriesBelongingToMacro, howManyPackageCategoriesBelongToMacroCategory$1 as howManyPackageCategoriesBelongToMacroCategory, shouldPackageGroupIncludeHotelPackage$1 as shouldPackageGroupIncludeHotelPackage };
